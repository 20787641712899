import React, { useEffect, useMemo, useState } from 'react';
import { BsGlobe2, BsStopFill } from 'react-icons/bs';

import cl from '@/components/components.module.css';
import { ChatModes, getModelSuffix, useChatControls } from '@/contexts/chat-controls';
import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';

import TextItem from './text-item';

type MessageTypingEffectType = {
    responseText: string | undefined;
    type: string;
    initialValue?: string;
    isLoading: boolean;
    smoothStream: boolean;
    useSearchEngine?: boolean;
    hasSources?: boolean;
    stopGeneration: () => void;
    handleCodeCopy?: (code: string) => void;
    mode?: ChatModes;
};

export function MessageTypingEffect(props: MessageTypingEffectType) {
    const { requestOption } = useChatControls();
    const {
        responseText = '',
        type,
        initialValue,
        smoothStream,
        stopGeneration,
        useSearchEngine,
        handleCodeCopy,
        mode,
    } = props;
    const responseTextTrimmed = useMemo(() => responseText!.replace(/[\ufff0-\uffff]+$/, ''), [responseText]);
    const [renderState, setRenderState] = useState({
        smoothText: initialValue ?? responseTextTrimmed,
        updating: false,
    });
    useEffect(() => {
        const perRound = 4000;
        const smoothSteps = 10;
        const minChars = 3;
        if (renderState.smoothText !== responseTextTrimmed) {
            if (!renderState.updating) {
                if (!smoothStream) {
                    setRenderState({
                        smoothText: responseTextTrimmed,
                        updating: false,
                    });
                    return;
                }
                const bufferStepLength = Math.floor(
                    (responseTextTrimmed!.length - renderState.smoothText.length) / smoothSteps,
                );
                const nextLength = renderState.smoothText.length + Math.max(bufferStepLength, minChars);
                const newText = responseTextTrimmed!.slice(0, nextLength);
                setRenderState({
                    smoothText: newText,
                    updating: true,
                });
                setTimeout(
                    () => {
                        setRenderState((old) => ({
                            ...old,
                            updating: false,
                        }));
                    },
                    Math.floor(perRound / smoothSteps),
                );
            }
        }
    }, [renderState, responseTextTrimmed]);

    return (
        <Box
            width={'100%'}
            border="1px solid #313845"
            borderRadius={'6px'}
            py={['12px', '24px']}
            px={['14px', '26px']}
            className={cl.messageContainer}
            height={'100%'}
        >
            <Text
                textStyle={'body-18'}
                color={'#8151BC'}
                fontFamily={'input-mono-medium'}
                textAlign={'start'}
                display={'flex'}
                textTransform={'uppercase'}
                mb={'12px'}
            >
                {!mode && (
                    <>
                        Reka {getModelSuffix(requestOption.modelName)}
                        {useSearchEngine && props.hasSources && (
                            <Tooltip label={'Search Tool Used'}>
                                <Text as="span" fontSize={'xs'} position={'relative'} top={'-6px'} left={'4px'}>
                                    <BsGlobe2 />
                                </Text>
                            </Tooltip>
                        )}
                    </>
                )}
                {mode === 'pdf' && 'PDF chat'}
                {mode === 'agent' && <>Nexus</>}
            </Text>
            <TextItem handleCodeCopy={handleCodeCopy} content={renderState.smoothText} isLoading={true}></TextItem>
            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} height={'20px'}>
                <Button
                    onClick={stopGeneration}
                    leftIcon={<BsStopFill />}
                    fontWeight={400}
                    color={'border-alt'}
                    width={'auto'}
                    size={'md'}
                    variant={'link'}
                    position={'absolute'}
                    bottom={'12px'}
                    padding={'8px'}
                    background={'background-secondary'}
                >
                    Stop generating
                </Button>
            </Box>
        </Box>
    );
}
