import React, { ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BsInfoCircle } from 'react-icons/bs';
import * as yup from 'yup';
import { TestConfig } from 'yup';

import { UserResource } from '@/api/firestore';
import {
    Form,
    FormError,
    FormField,
    FormFooter,
    FormResetButton,
    FormResetDefaultButton,
    FormRow,
    FormSubmitButton,
    FormToggle,
    Section,
} from '@/components/settings-modal/form-components';
import { configValue } from '@/config/config-value';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useChatHistoryStore } from '@/store/hooks/use-chat-history-store';
import { useChats } from '@/store/hooks/use-chat-store';
import { httpPost } from '@/utils/http-utils';
import { Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@sentry/nextjs';

const passwordResolver = yupResolver(
    yup.object().shape({
        password: yup.string().required('required'),
        newPassword: yup.string().required('required').min(7, 'Password length should be at least 7 characters'),
        confirmPassword: yup
            .string()
            .required('required')
            .oneOf([yup.ref('newPassword')], 'Passwords must match'),
    }),
);

const apiResolver = yupResolver(
    yup.object().shape({
        apikey: yup.string().required('required').min(7, 'api key length should be at least 64 characters'),
    }),
);

export function ApiForm() {
    const { setApiKeyAction, apiKey, appUser } = useAuthStore();

    async function changeApikey({ apikey: newApiKey }: { apikey: string }) {
        if (newApiKey === apiKey) return new FormError({ apikey: 'Same api key as before' });
        try {
            const response = await httpPost(`${configValue.baseURL}/checkapikey`, {}, newApiKey);
            const res = await response.json();

            if (res) {
                setApiKeyAction(newApiKey);
                UserResource.updateUserData(appUser!.uid, { apiKey: newApiKey }).catch(captureException);
                return;
            } else {
                return new FormError({ apikey: 'Invalid key' });
            }
        } catch (error: any) {
            return new FormError({ root: 'Unable to verify api key at this moment' });
        }
    }

    const clearApiKey = () => {
        setApiKeyAction('');
        UserResource.updateUserData(appUser!.uid, { apiKey: '' }).catch(captureException);
    };

    return (
        <Form resolver={apiResolver} onSubmit={changeApikey}>
            <FormField placeholder={apiKey || 'API key'} type={'text'} name={'apikey'}>
                Reka API key
            </FormField>
            <FormFooter>
                <FormSubmitButton>Save</FormSubmitButton>
                <FormResetButton />
                {apiKey && <DeleteButton onClick={clearApiKey} />}
            </FormFooter>
        </Form>
    );
}

function DeleteButton({ onClick }: { children?: ReactNode; onClick: () => any }) {
    const { reset, formState } = useFormContext();
    const handleClick = () => {
        onClick();
        setTimeout(reset, 10);
    };
    if (formState.isDirty) return null;
    return (
        <Button onClick={handleClick} textTransform={'none'} variant={'ghost'} size={'md'}>
            delete
        </Button>
    );
}

export function ChatFeaturesForm() {
    const { toggleFeatureAction, enabledFeatures } = useAppConfig();

    function changeEnabledFeatures(data: Record<string, boolean>) {
        Object.entries(data).forEach(([feature, enabled]) =>
            // @ts-ignore
            toggleFeatureAction({ feature, enabled }),
        );
    }

    return (
        <>
            <FormToggle value={enabledFeatures.rekaModes} onChange={changeEnabledFeatures} name={'rekaModes'}>
                Reka Modes
            </FormToggle>
            <FormToggle value={enabledFeatures.speech2speech} onChange={changeEnabledFeatures} name={'speech2speech'}>
                Speech to speech
            </FormToggle>
            <FormToggle
                value={enabledFeatures.codeInterpreter}
                onChange={changeEnabledFeatures}
                name={'codeInterpreter'}
            >
                Code interpreter
            </FormToggle>
            <FormToggle value={enabledFeatures.longContext} onChange={changeEnabledFeatures} name={'longContext'}>
                Long Context
            </FormToggle>
            <FormToggle value={enabledFeatures.customUrl} onChange={changeEnabledFeatures} name={'customUrl'}>
                Custom URL
            </FormToggle>
            <FormToggle
                value={enabledFeatures.flashDefaultSearch}
                onChange={changeEnabledFeatures}
                name={'flashDefaultSearch'}
            >
                Flash uses search by default
            </FormToggle>
            <FormToggle value={enabledFeatures.rekaCore} onChange={changeEnabledFeatures} name={'rekaCore'}>
                Reka Core
            </FormToggle>
            <FormToggle
                value={enabledFeatures.interleaveMedia}
                onChange={changeEnabledFeatures}
                name={'interleaveMedia'}
            >
                Interleave media
            </FormToggle>
            <FormToggle value={enabledFeatures.audioUpload} onChange={changeEnabledFeatures} name={'audioUpload'}>
                Enable audio upload
            </FormToggle>
            <FormToggle value={enabledFeatures.videoUpload} onChange={changeEnabledFeatures} name={'videoUpload'}>
                Enable video upload
            </FormToggle>
            <FormToggle value={enabledFeatures.retrieval} onChange={changeEnabledFeatures} name={'retrieval'}>
                Enable retrieval
            </FormToggle>
            <FormToggle value={enabledFeatures.searchEngine} onChange={changeEnabledFeatures} name={'searchEngine'}>
                Enable search engine
            </FormToggle>
            <FormToggle value={enabledFeatures.modelSelector} onChange={changeEnabledFeatures} name={'modelSelector'}>
                Model selector
            </FormToggle>
            <FormToggle
                value={enabledFeatures.personaSelector}
                onChange={changeEnabledFeatures}
                name={'personaSelector'}
            >
                Persona
            </FormToggle>
            <FormToggle
                value={enabledFeatures.showSystemPrompts}
                onChange={changeEnabledFeatures}
                name={'showSystemPrompts'}
            >
                Show System Prompts
            </FormToggle>
            <FormToggle value={enabledFeatures.exportChat} onChange={changeEnabledFeatures} name={'exportChat'}>
                Export chat
            </FormToggle>
        </>
    );
}

const optionalInt: TestConfig = {
    name: 'optionalInt',
    test: (v: any) =>
        yup
            .number()
            .min(0)
            .integer()
            .isValid(v || 0),
    message: 'must be a positive integer',
};

const optionalFloat: TestConfig = {
    name: 'optionalFloat',
    test: (v: any) =>
        yup
            .number()
            .min(0)
            .max(1)
            .isValid(v || 0),
    message: 'must be a valid float',
};

const paramsResolver = yupResolver(
    yup.object().shape({
        modelName: yup.string(),
        requestOutputLen: yup.string().test(optionalInt),
        temperature: yup.string().test(optionalFloat),
        randomSeed: yup.string().test(optionalInt),
        runtimeTopK: yup.string().test(optionalInt),
        runtimeTopP: yup.string().test(optionalFloat),
        repetitionPenalty: yup.string().test(optionalFloat),
        lenPenalty: yup.string().test(optionalFloat),
        stopTokens: yup.string(),
        assistantStartText: yup.string(),
    }),
);
export function ChatApiParamsForm() {
    const { setChatParamsAction, chatParameters, resetChatParamsAction } = useAppConfig();
    const { stopTokens, ...rest } = chatParameters;
    const stopTokensStr = stopTokens?.join(',');
    function changeChatParameters(data: any) {
        const params = {
            modelName: data.modelName === '' ? undefined : data.modelName,
            requestOutputLen: isNaN(parseFloat(data.requestOutputLen)) ? undefined : parseFloat(data.requestOutputLen),
            temperature: isNaN(parseFloat(data.temperature)) ? undefined : parseFloat(data.temperature),
            randomSeed: isNaN(parseFloat(data.randomSeed)) ? undefined : parseFloat(data.randomSeed),
            runtimeTopK: isNaN(parseFloat(data.runtimeTopK)) ? undefined : parseFloat(data.runtimeTopK),
            runtimeTopP: isNaN(parseFloat(data.runtimeTopP)) ? undefined : parseFloat(data.runtimeTopP),
            repetitionPenalty: isNaN(parseFloat(data.repetitionPenalty))
                ? undefined
                : parseFloat(data.repetitionPenalty),
            lenPenalty: isNaN(parseFloat(data.lenPenalty)) ? undefined : parseFloat(data.lenPenalty),
            stopTokens: data.stopTokens === '' ? undefined : data.stopTokens.split(','),
            assistantStartText: data.assistantStartText === '' ? undefined : data.assistantStartText,
        };
        setChatParamsAction(params);
    }

    return (
        <Form resolver={paramsResolver} values={{ ...rest, stopTokens: stopTokensStr }} onSubmit={changeChatParameters}>
            <FormField placeholder={'String, name of the model.'} type={'text'} name={'modelName'}>
                model name
            </FormField>
            <FormField placeholder={'Integer, number of tokens to generate'} type={'number'} name={'requestOutputLen'}>
                request output len
            </FormField>
            <FormField
                placeholder={'Float, sampling temperature, higher is more random.'}
                type={'text'}
                name={'temperature'}
            >
                temperature
            </FormField>
            <FormField
                placeholder={'Int, random seed, change to generate different output.'}
                type={'number'}
                name={'randomSeed'}
            >
                random seed
            </FormField>
            <FormField placeholder={'Int, only sample from the top k tokens.'} type={'number'} name={'runtimeTopK'}>
                runtime top k
            </FormField>
            <FormField
                placeholder={'Float, only sample from top tokens that sum to top_p probability.'}
                type={'text'}
                name={'runtimeTopP'}
            >
                runtime top p
            </FormField>
            <FormField
                placeholder={'Float, higher penalizes repetition more.'}
                type={'text'}
                name={'repetitionPenalty'}
            >
                repetition penalty
            </FormField>
            <FormField placeholder={'Float, encourage the model to be concise.'} type={'text'} name={'lenPenalty'}>
                len penalty
            </FormField>
            <FormField
                placeholder={'String (comma sep), stop generating when one is sampled.'}
                type={'text'}
                name={'stopTokens'}
            >
                stop tokens
            </FormField>
            <FormField
                placeholder={'String, how the following assistant response should start.'}
                type={'text'}
                name={'assistantStartText'}
            >
                assistant start text
            </FormField>
            <FormFooter>
                <FormSubmitButton>Save parameters</FormSubmitButton>
                <FormResetDefaultButton onClick={resetChatParamsAction}>Use default</FormResetDefaultButton>
                <FormResetButton />
            </FormFooter>
        </Form>
    );
}

export function MigrateChatHelper() {
    const { migrateChatHistory } = useChats();
    const [loading, setLoading] = useState(false);
    const { chatHistory, clearChatHistoryAction } = useChatHistoryStore();
    const [showTool] = useState(chatHistory && chatHistory.length > 0);
    if (!showTool) {
        return null;
    }
    const isDisabled = loading || !chatHistory || chatHistory.length === 0;
    const handleMigrate = async () => {
        if (loading || isDisabled) return;
        setLoading(true);
        await migrateChatHistory();
        setLoading(false);
    };
    return (
        <Section>
            <Text mb={'8px'}>
                Migrate or clear your chat history
                <Tooltip
                    label={'We recently changed our storage solution, decide how to handle your previous chat history'}
                >
                    <Icon fontSize={'16px'} viewBox={'0 0 16 16'} ml={'8px'}>
                        <BsInfoCircle />
                    </Icon>
                </Tooltip>
            </Text>
            <Flex gap={'12px'}>
                <Button
                    onClick={handleMigrate}
                    background={'whiteAlpha.800'}
                    color={'background-main'}
                    colorScheme={'gray'}
                    variant={'solid'}
                    size={'md'}
                    isDisabled={isDisabled}
                    isLoading={loading}
                >
                    Migrate
                </Button>
                <Button
                    isDisabled={isDisabled}
                    onClick={clearChatHistoryAction}
                    textTransform={'none'}
                    variant={'ghost'}
                    size={'md'}
                >
                    Clear
                </Button>
            </Flex>
        </Section>
    );
}
