import React, { useEffect, useRef, useState } from 'react';
import { BsFolder } from 'react-icons/bs';

import { rekaFactory } from '@/api/reka';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import {
    Button,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tag,
    TagCloseButton,
    TagLabel,
    Tooltip,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';

export function FolderModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [files, setFiles] = useState<string[]>([]);
    const formRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (isOpen) {
            const fetchFiles = async () => {
                const reka = await rekaFactory(null);
                const { data } = await reka.getFilesV1();
                if (data) setFiles(data.files);
            };
            fetchFiles();
        }
    }, [isOpen, setFiles]);
    const handleRemove = async (name: string) => {
        setFiles((f) => f.filter((file) => file !== name));
        const reka = await rekaFactory(null);
        reka.removeFileV1(name.split('/').at(-2)!);
    };
    const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const reka = await rekaFactory(null);
        setFiles((f) => [...f, file.name]);
        const { data } = await reka.uploadFileV1({ file });
        if (data) setFiles((f) => f.filter((name) => name !== file.name).concat([data]));
    };
    const handleClick = () => formRef.current!.click();
    return (
        <>
            <Tooltip label={'Manage files'}>
                <IconButton
                    isRound
                    size={'sm'}
                    fontSize={'20px'}
                    variant={'solid'}
                    icon={<BsFolder />}
                    aria-label={'Clear context'}
                    onClick={onOpen}
                />
            </Tooltip>
            <Modal
                onOverlayClick={onClose}
                isCentered
                isOpen={isOpen}
                onClose={onClose}
                size={'md'}
                scrollBehavior={'inside'}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'background-main'}>
                    <ModalCloseButton />
                    <ModalHeader>File system</ModalHeader>
                    <ModalBody alignItems={'center'} justifyContent={'center'} minHeight={'300px'}>
                        <VStack alignItems={'flex-start'}>
                            {files.map((file, index) => (
                                <Tag key={index} borderRadius="full" variant="solid">
                                    <TagLabel>{file.split('/').at(-1)} </TagLabel>
                                    <TagCloseButton onClick={() => handleRemove(file)} />
                                </Tag>
                            ))}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleClick}>
                            Upload File
                        </Button>
                        <Input onChange={handleUpload} ref={formRef} type={'file'} value="" display={'none'} />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
