import mime from 'mime-types';
import { useRouter } from 'next/router';
import React, { useMemo, useRef, useState } from 'react';
import { BsFiletypeJson } from 'react-icons/bs';
import { FiUpload } from 'react-icons/fi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import ReactPlayer from 'react-player';

import { CodeRenderer } from '@/components/code-renderer';
import { mediaMimeTypes } from '@/components/input-controls/constants';
import { FileUploader, useDocumentPaste } from '@/components/input-controls/input-controls';
import { getMediaType, useChatControls } from '@/contexts/chat-controls';
import { useSendMessage } from '@/hooks/use-send-message';
import { Chat } from '@/utils/types';
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Image as Img,
    Stack,
    Switch,
    Text,
} from '@chakra-ui/react';

import cl from './perception-agent.module.css';

const attributesPromptMap = {
    'spatial-relationships': `"SpatialRelationships": "A string field that explains spatial arrangement objects. Pay attention to the location of EACH OBJECT. "`,
    background: `"Background": "A string field that describes the background setting. This string should be concise."`,
    'seo-phrases': `"SeoPhrases": "A list of strings of the top multiword phrases that holistically describe the image for search and SEO purposes. Each phrase should be 2-3 words."`,
    description: `"Description": "A string field that describes the image in a few words as possible. This description must only contain factual information observed in the image. This cannot exceed 30 words."`,
    people: `"People": "A string field that describes any persons in the image. Describe what they are wearing and point out what they might be doing. If there is no one in the image keep this field blank."`,
    'object-recognition': `"ObjectRecognition": "A list of strings that identifies key objects in a few words."`,
};

type Attributes = keyof typeof attributesPromptMap;

export function PerceptionAgent({ chat }: { chat?: Chat | null }) {
    const [loading, setLoading] = useState(false);
    const [media, setMedia] = useState<null | {
        url: string;
        type: 'image' | 'video';
    }>(null);
    const { uploadControls, showDropZones, setChatMode } = useChatControls();

    const { handleMessageSendStateLess } = useSendMessage();

    const mediaUrl = media?.url || null;
    const mediaType = media?.type || null;
    const [errorMsg, setError] = useState('');
    const [responses, setResponses] = useState<Record<number, string>>({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [videoReady, setVideoReady] = useState(false);
    const videoUrlRef = useRef('');
    const videoKeyRef = useRef('');
    const [val, setVal] = useState<Array<Attributes>>(['description', 'people', 'object-recognition']);
    const [currentVal, setCurrent] = useState<Array<Attributes>>(['description', 'people', 'object-recognition']);

    useDocumentPaste((event) => {
        if ((event.target as HTMLElement)?.id === 'custom_url_input') return;
        if (loading) return;
        if (event.clipboardData?.files && event.clipboardData?.files.length > 0) {
            const file = event.clipboardData.files[0];
            const mimeType = mime.lookup(file.name);
            if (mimeType && mediaMimeTypes.includes(mimeType)) {
                handleMedia(file);
            }
        }
    });

    const handleMediaDrop = (e: React.DragEvent) => {
        e.preventDefault();
        if (loading) return;
        const item = e.dataTransfer.items[0];
        const file = item.getAsFile();
        if (file) {
            handleMedia(file);
        }
    };

    const handleMedia = async (file: File, attributes?: Attributes[]) => {
        setChatMode('perception');
        const mediaType = getMediaType(file.name);
        if (mediaType === 'image') {
            setLoading(true);
            const url = await fileToBase64(file);
            setMedia({ url, type: mediaType });
            await handleImage(url, mediaType, attributes || currentVal);
            setLoading(false);
        } else if (mediaType === 'video') {
            const objectUrl = URL.createObjectURL(file);
            setMedia({ url: objectUrl, type: mediaType });
            videoUrlRef.current = objectUrl;
            setCurrentIndex(0);
            handleProgress({ playedSeconds: 0, reset: true });
        }
    };
    const json = extractCodeBlock((mediaType === 'image' ? responses?.[0] : responses?.[currentIndex]) ?? '{}');

    const playerRef = useRef<ReactPlayer | null>(null);
    const handleProgress = async ({
        playedSeconds,
        attributes,
        reset = false,
    }: {
        playedSeconds: number;
        attributes?: Attributes[];
        reset?: boolean;
    }) => {
        const currentVideoUrl = videoUrlRef.current;
        if (reset) {
            videoKeyRef.current = Math.random().toString();
            setResponses({});
            setVideoReady(false);
        }

        // This allows us to ignore streams when conditions change
        const videoKey = videoKeyRef.current;
        const index = Math.floor(playedSeconds / 4);
        setCurrentIndex(index);
        const queue = [index, index + 1, index + 2].filter((i) => !responses[i] || reset);
        if (queue.length === 0) return;
        for (let i of queue) {
            const isFirstRequest = i === index;
            if (isFirstRequest) {
                setLoading(true);
            }
            setResponses((v) => ({ ...v, [i]: '{}' }));
            let frame: string;
            try {
                frame = await captureFrame(currentVideoUrl, i * 4 + 2);
            } catch (e) {
                continue;
            }
            handleMessageSendStateLess(
                `
           Describe the image according to the following instructions. You must output a JSON object as defined below.

            {${(attributes || currentVal).map((v) => attributesPromptMap[v]!).join(',')}}
            
            Focus solely on the objects, actions, and properties that are directly observable in the image. Avoid including any inferred or likely co-occurring elements that are not explicitly visible.

            Only start your response with \`\`\`json
        `,
                null,
                {
                    mediaUrl: frame,
                    mediaType: 'image',
                    mode: 'perception',
                },
                (text: string) => {
                    if (currentVideoUrl !== videoUrlRef.current || videoKey !== videoKeyRef.current) return false;
                    if (isFirstRequest) {
                        setVideoReady(true);
                    }
                    setResponses((v) => ({ ...v, [i]: text }));
                    return true;
                },
            ).then(() => {
                if (isFirstRequest) {
                    setLoading(false);
                    setVideoReady(true);
                }
            });
        }
    };
    const handleImage = async (url: string, type: any, attributes: Attributes[]) => {
        setLoading(true);
        await handleMessageSendStateLess(
            `
            Describe the image according to the following instructions. You must output a JSON object as defined below.

            {${(attributes || currentVal).map((v: Attributes) => attributesPromptMap[v]!).join(',')}}
            
            Focus solely on the objects, actions, and properties that are directly observable in the image. Avoid including any inferred or likely co-occurring elements that are not explicitly visible.
            `,
            null,
            {
                mediaUrl: url,
                mediaType: type,
                mode: 'perception',
            },
            (text: string) => {
                setResponses((v) => ({ ...v, [0]: text }));
                return true;
            },
        );
        setLoading(false);
    };

    const handleSwitchChange = (attribute: Attributes) => {
        setVal((prev) => {
            const newVal = prev.includes(attribute) ? prev.filter((item) => item !== attribute) : [...prev, attribute];

            // Ensure "description" is always included
            if (!newVal.includes('description')) {
                newVal.push('description');
            }

            return newVal;
        });
    };

    const applyAttributes = () => {
        setCurrent(val);
        if (mediaType === 'image') {
            setResponses({});
            handleImage(mediaUrl!, mediaType, val);
        } else if (mediaType === 'video' && playerRef.current) {
            handleProgress({ playedSeconds: currentIndex * 4, attributes: val, reset: true });
        }
    };

    const isSame = useMemo(
        () => currentVal.every((v) => val.includes(v) && currentVal.length === val.length),
        [currentVal, val],
    );

    const router = useRouter();

    const handleGoHome = () => {
        router.push('/chat');
    };

    return (
        <Flex
            h={['auto', 'auto', '100%']}
            flexDirection={['column', 'column', 'row']} // Switch to column layout on smaller screens
            width={'100%'}
            height={'100%'}
        >
            <Box
                display="grid"
                gridTemplateColumns={['1fr', '1fr', '4fr 6fr']} // Responsive array syntax for different screen sizes
                width="100%"
                height="100%"
            >
                <Flex
                    flexDirection={'column'}
                    justifyContent={'start'}
                    alignItems={'center'}
                    h={['auto', 'auto', '100%']}
                    color={'#2C1A6B'}
                    backgroundColor={'#E1E6F2'}
                    px={6}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'start'}
                        alignItems={'center'}
                        width={'100%'}
                        mt={8}
                    >
                        <IconButton
                            aria-label="Go back to home"
                            icon={<MdKeyboardArrowLeft />}
                            onClick={() => handleGoHome()}
                            fontSize="30px"
                            color={'#2C1A6B'}
                            variant="ghost"
                            mr={2}
                        />
                        <Text fontSize={'1.4em'} fontWeight="bold">
                            Perception Mode
                        </Text>
                    </Box>
                    <Box width={'90%'}>
                        <Text fontSize={'1em'} mt={'13px'}>
                            Easily extract structured details from your videos or images with Nexus
                        </Text>
                        <Flex
                            mt={6}
                            borderRadius={'8px'}
                            bg={'DEE3EE'}
                            position={'relative'}
                            onDrop={handleMediaDrop}
                            minH={'230px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            boxShadow="inset 0 0 10px rgba(0, 0, 0, 0.17)"
                        >
                            {showDropZones && !loading && (
                                <Box
                                    borderRadius={'8px'}
                                    border={'2px dashed'}
                                    borderColor={'border-alt'}
                                    position={'absolute'}
                                    top={0}
                                    left={0}
                                    width={'100%'}
                                    h={'100%'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    bg={'background-secondary'}
                                >
                                    <Text fontSize={'lg'}>Drop file here to upload</Text>
                                </Box>
                            )}
                            <Flex alignItems={'center'} justifyContent={'center'} p={3}>
                                {mediaType === 'image' && mediaUrl && (
                                    <Img boxSize={`300px`} objectFit="contain" src={mediaUrl} />
                                )}
                                {mediaType === 'video' && mediaUrl && (
                                    <ReactPlayer
                                        playing={videoReady}
                                        muted={true}
                                        ref={playerRef}
                                        onProgress={handleProgress}
                                        url={mediaUrl}
                                        controls={videoReady}
                                        width={'100%'}
                                        height={'100%'}
                                        maxH={'300px'}
                                        objectFit={'object-fit'}
                                    />
                                )}
                                <FileUploader uploadMedia={handleMedia} mimetype={'photovideo'}>
                                    {!mediaUrl && (
                                        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                            <Box
                                                backgroundImage="
                                                repeating-linear-gradient(90deg, #A8B4D0, #A8B4D0 4px, transparent 4px, transparent 12px), 
                                                repeating-linear-gradient(180deg, #A8B4D0, #A8B4D0 4px, transparent 4px, transparent 12px), 
                                                repeating-linear-gradient(90deg, #A8B4D0, #A8B4D0 4px, transparent 4px, transparent 12px), 
                                                repeating-linear-gradient(180deg, #A8B4D0, #A8B4D0 4px, transparent 4px, transparent 12px)
                                            "
                                                backgroundPosition="left top, right top, left bottom, left top"
                                                backgroundRepeat="repeat-x, repeat-y, repeat-x, repeat-y"
                                                backgroundSize="100% 2px, 2px 100%, 100% 2px, 2px 100%"
                                                padding="15px"
                                                fontSize="22px"
                                                borderRadius="5px"
                                            >
                                                <FiUpload />
                                            </Box>
                                            <Text color={'#1A1333'} fontSize={'1em'} mt={2}>
                                                Drag an image or video here
                                                <br />
                                                <Text as={'span'} color={'#39414D'} fontSize={'14px'}>
                                                    or click here to upload file
                                                </Text>
                                            </Text>
                                        </Flex>
                                    )}
                                </FileUploader>
                            </Flex>
                            {errorMsg && (
                                <Text color={'red'} px={3} position={'absolute'} bottom={'-28px'} left={0}>
                                    {errorMsg}
                                </Text>
                            )}
                        </Flex>
                        {mediaUrl && (
                            <Button
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                aria-label="Upload media"
                                fontSize="16px"
                                borderRadius="5px"
                                color="white"
                                variant={'darkPurple'}
                                mt={2}
                                py={2}
                                w="100%"
                            >
                                <FileUploader uploadMedia={handleMedia} mimetype={'photovideo'}>
                                    <Flex
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        w="100%"
                                    >
                                        <FiUpload />
                                        <Text fontWeight="500" ml={2}>
                                            Upload
                                        </Text>
                                    </Flex>
                                </FileUploader>
                            </Button>
                        )}
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            width={'100%'}
                            fontSize={'13px'}
                            mt={2}
                        >
                            <Text>MP4, WebM, AVI or MOV</Text>
                            <Text>128MB Max.</Text>
                        </Box>
                        <Box mt={6} mb={5}>
                            <FormControl as="fieldset">
                                <FormLabel as="legend" fontSize="lg" mb={4}>
                                    Select Attributes
                                </FormLabel>
                                <Stack spacing={4}>
                                    <FormControl display="flex" alignItems="center" justifyContent={'space-between'}>
                                        <FormLabel
                                            htmlFor="description"
                                            mb="0"
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            fontSize="md"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.83227 7.91665C5.63174 6.25417 6.59546 5.91404 9.99892 5.83331M9.99892 5.83331C13.4351 5.91713 14.45 6.33189 14.1656 7.91665M9.99892 5.83331V14.1666M8.33227 14.1666H11.6656"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M3.24286 3.24268C2.0835 4.40205 2.0835 6.26803 2.0835 9.99998C2.0835 13.7319 2.0835 15.5979 3.24286 16.7573C4.40224 17.9166 6.26821 17.9166 10.0002 17.9166C13.7321 17.9166 15.5981 17.9166 16.7575 16.7573C17.9168 15.5979 17.9168 13.7319 17.9168 9.99998C17.9168 6.26803 17.9168 4.40205 16.7575 3.24268C15.5981 2.08331 13.7321 2.08331 10.0002 2.08331C6.26821 2.08331 4.40224 2.08331 3.24286 3.24268Z"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Description
                                        </FormLabel>
                                        <Switch
                                            id="description"
                                            isChecked={val.includes('description')}
                                            sx={{
                                                '.chakra-switch__track[data-checked]:not([data-theme])': {
                                                    backgroundColor: '#2C1A6B',
                                                },
                                            }}
                                            onChange={() => handleSwitchChange('description')}
                                        />
                                    </FormControl>
                                    <FormControl display="flex" alignItems="center" justifyContent={'space-between'}>
                                        <FormLabel
                                            htmlFor="object-recognition"
                                            mb="0"
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            fontSize="md"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.48609 1.66684C5.14725 1.72013 3.78115 1.94276 2.84777 2.87291C2.03086 3.68699 1.75779 4.83137 1.6665 6.66684M12.5136 1.66684C14.8524 1.72013 16.2185 1.94276 17.1519 2.87291C17.9688 3.68699 18.2419 4.83137 18.3332 6.66684M12.5136 18.3335C14.8524 18.2803 16.2185 18.0576 17.1519 17.1274C17.9688 16.3134 18.2419 15.169 18.3332 13.3335M7.48609 18.3335C5.14725 18.2803 3.78115 18.0576 2.84777 17.1274C2.03086 16.3134 1.75779 15.169 1.6665 13.3335"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.5002 12.5002L14.1668 14.1668M13.3335 9.58351C13.3335 7.51241 11.6546 5.8335 9.5835 5.8335C7.5124 5.8335 5.8335 7.51241 5.8335 9.58351C5.8335 11.6546 7.5124 13.3335 9.5835 13.3335C11.6546 13.3335 13.3335 11.6546 13.3335 9.58351Z"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Object Recognition
                                        </FormLabel>
                                        <Switch
                                            id="object-recognition"
                                            isChecked={val.includes('object-recognition')}
                                            sx={{
                                                '.chakra-switch__track[data-checked]:not([data-theme])': {
                                                    backgroundColor: '#2C1A6B',
                                                },
                                            }}
                                            onChange={() => handleSwitchChange('object-recognition')}
                                        />
                                    </FormControl>
                                    <FormControl display="flex" alignItems="center" justifyContent={'space-between'}>
                                        <FormLabel
                                            htmlFor="people"
                                            mb="0"
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            fontSize="md"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2.0835 13.7502C2.0835 14.5246 2.0835 14.9118 2.14755 15.2338C2.41057 16.5561 3.44423 17.5897 4.76653 17.8527C5.08852 17.9168 5.47574 17.9168 6.25016 17.9168M17.9168 13.7502C17.9168 14.5246 17.9168 14.9118 17.8527 15.2338C17.5897 16.5561 16.5561 17.5897 15.2338 17.8527C14.9118 17.9168 14.5246 17.9168 13.7502 17.9168M17.9168 6.25016C17.9168 5.47574 17.9168 5.08852 17.8527 4.76653C17.5897 3.44423 16.5561 2.41057 15.2338 2.14755C14.9118 2.0835 14.5246 2.0835 13.7502 2.0835M2.0835 6.25016C2.0835 5.47574 2.0835 5.08852 2.14755 4.76653C2.41057 3.44423 3.44423 2.41057 4.76653 2.14755C5.08852 2.0835 5.47574 2.0835 6.25016 2.0835"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M10.0002 7.08348V5.41681M8.3335 9.58348V10.0001M11.6668 9.58348V10.0001M9.16683 7.08348H10.8335C12.4048 7.08348 13.1905 7.08348 13.6787 7.57163C14.1668 8.05978 14.1668 8.84548 14.1668 10.4168C14.1668 11.9881 14.1668 12.7738 13.6787 13.262C13.1905 13.7501 12.4048 13.7501 10.8335 13.7501H9.16683C7.59548 13.7501 6.8098 13.7501 6.32165 13.262C5.8335 12.7738 5.8335 11.9881 5.8335 10.4168C5.8335 8.84548 5.8335 8.05978 6.32165 7.57163C6.8098 7.08348 7.59548 7.08348 9.16683 7.08348Z"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            People
                                        </FormLabel>
                                        <Switch
                                            id="people"
                                            isChecked={val.includes('people')}
                                            sx={{
                                                '.chakra-switch__track[data-checked]:not([data-theme])': {
                                                    backgroundColor: '#2C1A6B',
                                                },
                                            }}
                                            onChange={() => handleSwitchChange('people')}
                                        />
                                    </FormControl>
                                    <FormControl display="flex" alignItems="center" justifyContent={'space-between'}>
                                        <FormLabel
                                            htmlFor="spatial-relationships"
                                            mb="0"
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            fontSize="md"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M10.0002 9.58348C10.4131 9.58348 10.797 9.41806 11.5647 9.08723L12.1187 8.84848C13.4842 8.26016 14.1668 7.96599 14.1668 7.50014C14.1668 7.03429 13.4842 6.74013 12.1187 6.15178L11.5647 5.91305C10.797 5.58223 10.4131 5.41681 10.0002 5.41681C9.58725 5.41681 9.20333 5.58223 8.43558 5.91305L7.88156 6.15178C6.51619 6.74013 5.8335 7.03429 5.8335 7.50014C5.8335 7.96599 6.51619 8.26016 7.88156 8.84848L8.43558 9.08723C9.20333 9.41806 9.58725 9.58348 10.0002 9.58348ZM10.0002 9.58348V14.5835"
                                                    stroke="#3D134D"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M14.1668 7.50012V12.5001C14.1668 12.966 13.4842 13.2601 12.1187 13.8485L11.5647 14.0872C10.797 14.418 10.4131 14.5835 10.0002 14.5835C9.58725 14.5835 9.20333 14.418 8.43558 14.0872L7.88156 13.8485C6.51619 13.2601 5.8335 12.966 5.8335 12.5001V7.50012"
                                                    stroke="#3D134D"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M7.62038 2.08344C5.4062 2.13406 4.1129 2.34557 3.22927 3.22921C2.34563 4.11284 2.13412 5.40614 2.0835 7.62032M12.3799 2.08344C14.5942 2.13406 15.8874 2.34557 16.7711 3.22921C17.6547 4.11284 17.8662 5.40614 17.9168 7.62032M12.3799 17.9168C14.5942 17.8661 15.8874 17.6546 16.7711 16.771C17.6547 15.8874 17.8662 14.5941 17.9168 12.3799M7.62038 17.9168C5.4062 17.8661 4.1129 17.6546 3.22927 16.771C2.34563 15.8874 2.13412 14.5941 2.0835 12.3799"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Spatial Relationships
                                        </FormLabel>
                                        <Switch
                                            id="spatial-relationships"
                                            isChecked={val.includes('spatial-relationships')}
                                            sx={{
                                                '.chakra-switch__track[data-checked]:not([data-theme])': {
                                                    backgroundColor: '#2C1A6B',
                                                },
                                            }}
                                            onChange={() => handleSwitchChange('spatial-relationships')}
                                        />
                                    </FormControl>
                                    <FormControl display="flex" alignItems="center" justifyContent={'space-between'}>
                                        <FormLabel
                                            htmlFor="background"
                                            mb="0"
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            fontSize="md"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.6665 13.1252C1.6665 13.1252 3.16262 12.5001 5.83317 12.5001C9.99984 12.5001 13.3332 15.0001 18.3332 15.0001"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M1.6665 17.5001H18.3332"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M10.4948 2.6898C8.12348 3.36986 6.58209 5.71032 6.67008 8.22932C6.68836 8.75254 6.69749 9.01412 6.92105 9.12979C7.14461 9.24546 7.37665 9.08154 7.84073 8.75354L8.87533 8.02246C9.03075 7.91265 9.21525 7.85975 9.40108 7.87168L11.8313 8.0278L13.8629 6.59214C14.0183 6.48233 14.2027 6.42943 14.3887 6.44137L15.6564 6.52281C16.2004 6.55776 16.4724 6.57523 16.6074 6.36581C16.7425 6.1564 16.6327 5.92998 16.4131 5.47714C15.3212 3.22503 12.8793 2.00601 10.4948 2.6898Z"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.0833 7.91681L13.75 14.1668M10.2381 2.50014L10 1.66681"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            Background
                                        </FormLabel>
                                        <Switch
                                            id="background"
                                            isChecked={val.includes('background')}
                                            sx={{
                                                '.chakra-switch__track[data-checked]:not([data-theme])': {
                                                    backgroundColor: '#2C1A6B',
                                                },
                                            }}
                                            onChange={() => handleSwitchChange('background')}
                                        />
                                    </FormControl>
                                    <FormControl display="flex" alignItems="center" justifyContent={'space-between'}>
                                        <FormLabel
                                            htmlFor="seo-phrases"
                                            mb="0"
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                            fontSize="md"
                                        >
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.1665 16.6667L6.17722 14.656M6.17722 14.656C6.84746 15.3262 7.77339 15.7408 8.79617 15.7408C10.8417 15.7408 12.4998 14.0825 12.4998 12.037C12.4998 9.99154 10.8417 8.33337 8.79617 8.33337C6.75064 8.33337 5.09243 9.99154 5.09243 12.037C5.09243 13.0598 5.50698 13.9858 6.17722 14.656Z"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                />
                                                <path
                                                    d="M2.49984 12.6565C1.96979 11.7429 1.6665 10.6826 1.6665 9.55196C1.6665 6.11753 4.46472 3.33337 7.9165 3.33337H12.0832C15.5349 3.33337 18.3332 6.11753 18.3332 9.55196C18.3332 12.2595 16.594 14.563 14.1665 15.4167"
                                                    stroke="#3D134D"
                                                    stroke-linecap="round"
                                                />
                                            </svg>
                                            Seo Phrases
                                        </FormLabel>
                                        <Switch
                                            id="seo-phrases"
                                            isChecked={val.includes('seo-phrases')}
                                            sx={{
                                                '.chakra-switch__track[data-checked]:not([data-theme])': {
                                                    backgroundColor: '#2C1A6B',
                                                },
                                            }}
                                            onChange={() => handleSwitchChange('seo-phrases')}
                                        />
                                    </FormControl>
                                    {mediaUrl && !isSame && (
                                        <Button isDisabled={loading} onClick={applyAttributes} variant="darkPurple">
                                            Apply
                                        </Button>
                                    )}
                                </Stack>
                            </FormControl>
                        </Box>
                    </Box>
                </Flex>
                <Flex
                    flexDirection={'column'}
                    backgroundColor={'background-secondary'}
                    alignItems={'center'}
                    height={'100%'}
                    justifyContent={'center'}
                    h={['auto', 'auto', '100%']}
                >
                    <CodeRenderer
                        isLoading={loading || chat?.loading}
                        message={'```json\n' + json + '\n```'}
                        innerClassname={cl.codeBlock}
                    />
                </Flex>
            </Box>
        </Flex>
    );
}

// Function to capture a specific frame
function captureFrame(videoSrc: string, timeInSeconds: number): Promise<string> {
    return new Promise((resolve, reject) => {
        // Create video element
        const video = document.createElement('video');
        video.src = videoSrc;

        // Load video metadata
        video.addEventListener('loadedmetadata', () => {
            // Ensure the specified time is within the video duration
            if (timeInSeconds > video.duration) {
                reject(new Error('Time exceeds video duration'));
                return;
            }
            // Seek to the specified time
            video.currentTime = timeInSeconds;
        });

        // Listen for seeked event to capture the frame
        video.addEventListener('seeked', () => {
            // Create canvas and set dimensions
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');

            if (!context) {
                reject(new Error('Failed to get canvas context'));
                return;
            }

            // Draw video frame to canvas
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Export as image
            const base64Image = canvas.toDataURL('image/jpeg');
            resolve(base64Image);
        });

        // Handle errors
        video.addEventListener('error', (e) => {
            reject(new Error(`Video failed to load: ${e.message}`));
        });
    });
}

function extractCodeBlock(content?: string) {
    if (!content) return '{}';
    // content is valid
    if (content.startsWith('{') || content.startsWith(' {')) {
        return content;
    }
    // find block using triple backticks
    // Regular expression to find the opening triple backticks with optional language identifier
    const openingCodeBlockRegex = /```[a-zA-Z]*\n?/;
    // Check if there is an opening triple backtick
    const startMatch = content.match(openingCodeBlockRegex);

    // If no opening triple backtick is found, return null
    if (!startMatch) {
        return '{}';
    }

    // Find the position of the opening triple backticks
    const startIndex = startMatch.index! + startMatch[0].length;

    // Check for the closing triple backticks
    const closingCodeBlockRegex = /```/;
    const endMatch = content.slice(startIndex).match(closingCodeBlockRegex);

    // If closing triple backticks are found, return the content between them
    if (endMatch) {
        const endIndex = startIndex + endMatch.index!;
        return content.slice(startIndex, endIndex).trim();
    }

    // find the closing brackets as a last resort
    if (content.indexOf('}')) {
        return content.slice(startIndex, content.indexOf('}') + 1);
    }

    // If no closing backticks, return everything after the opening backticks
    return content.slice(startIndex).trim();
}

function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            try {
                // @ts-ignore
                resolve(reader.result);
            } catch (e) {
                reject(e);
            }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}
