import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

export function RightPanelContainer({ children, fullWidth }: { children: React.ReactNode; fullWidth: boolean }) {
    return (
        <Flex w={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Flex
                flexDirection="column"
                h={'var(--chakra-vh)'}
                w={['100%', '100%', '100%', '100%', fullWidth ? '100%' : '80%']}
            >
                {children}
            </Flex>
        </Flex>
    );
}
