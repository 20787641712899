import { Box, Flex, Stack, Text } from '@chakra-ui/react';

interface AgentCardProps {
    mode: string;
    title: string;
    description: string;
    onClick: () => void;
    disabled: boolean;
}

function AgentCard({ mode, title, description, onClick, disabled }: AgentCardProps) {
    return (
        <Box
            onClick={onClick}
            flex={1}
            rounded={6}
            bg={'background-secondary'}
            p={4}
            as={'button'}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            opacity={disabled ? 0.4 : 1}
        >
            <Flex gap={3}>
                <Stack textAlign="left">
                    <Text fontSize="lg" fontWeight={3}>
                        {title}
                    </Text>
                    <Text fontSize="xs">{description}</Text>
                </Stack>
            </Flex>
        </Box>
    );
}

export default AgentCard;
