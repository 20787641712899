import React, { useEffect, useRef } from 'react';
import { BiLinkExternal } from 'react-icons/bi';

import { FormRow, Section } from '@/components/settings-modal/form-components';
import { ApiForm, ChatApiParamsForm, ChatFeaturesForm, MigrateChatHelper } from '@/components/settings-modal/forms';
import { configValue } from '@/config/config-value';
import { auth } from '@/config/firebase';
import { useUser } from '@auth0/nextjs-auth0/client';
import { ModalProps } from '@chakra-ui/react';
import {
    Button,
    Divider,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorMode,
} from '@chakra-ui/react';
import { BrowserClient, getClient } from '@sentry/react';

import { PersonaConfigForm } from './persona-form';

export function SettingsModal({
    isOpen,
    onClose,
    closeParent,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { closeParent?: () => void }) {
    return (
        <Modal isCentered scrollBehavior={'inside'} size={['full', '2xl']} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent height={'min(var(--chakra-vh),600px)'} bg={'modal-background'}>
                <ModalHeader fontWeight={400}>Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs>
                        {configValue.showAdminSettings && (
                            <TabList>
                                <Tab>General</Tab>
                                <Tab>Features</Tab>
                                <Tab>Persona</Tab>
                                <Tab>Advanced</Tab>
                            </TabList>
                        )}
                        <TabPanels>
                            <TabPanel>
                                <GeneralTab onClose={onClose} closeParent={closeParent} />
                            </TabPanel>
                            <TabPanel>
                                <Section>
                                    <ChatFeaturesForm />
                                </Section>
                            </TabPanel>
                            <TabPanel>
                                <Section>
                                    <PersonaConfigForm />
                                </Section>
                            </TabPanel>
                            <TabPanel>
                                <Section>
                                    <ChatApiParamsForm />
                                </Section>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

function GeneralTab({ onClose, closeParent }: { onClose: () => void; closeParent?: () => void }) {
    const { user } = useUser();
    const ref = useRef<any>(null);
    const { colorMode, forced, setColorMode } = useColorMode();

    useEffect(() => {
        const client = getClient<BrowserClient>();
        ref.current = client?.getIntegrationByName('Feedback');
    }, []);

    if (!user) return null;

    const handleFeedback = () => {
        onClose();
        if (closeParent) closeParent();
        ref.current?.openDialog();
    };

    return (
        <>
            <Section>
                <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'12px'}>
                    <FormRow label={'Account Email'}>
                        <Flex gap={'12px'}>
                            <Text>{user?.email}</Text>
                            <Button
                                onClick={() => auth?.signOut()}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                href={'/bff/auth/logout'}
                            >
                                Log out
                            </Button>
                        </Flex>
                    </FormRow>
                    <Divider borderColor={'rgba(101, 107, 117, 0.25)'} />
                    <FormRow label={'Theme'}>
                        <Select
                            borderColor={'border-main'}
                            defaultValue={colorMode}
                            onChange={(e) => {
                                setColorMode(e.target.value);
                            }}
                            maxWidth={'120px'}
                        >
                            <option value={'system'}>System</option>
                            <option value={'light'}>Light</option>
                            <option value={'dark'}>Dark</option>
                        </Select>
                    </FormRow>
                    <Divider borderColor={'rgba(101, 107, 117, 0.25)'} />
                    <ApiForm />
                </Flex>
            </Section>
            <Section>
                <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'18px'}>
                    <Heading fontSize={'12px'} fontWeight={700} as={'h3'} textTransform={'uppercase'}>
                        Participate in our work
                    </Heading>
                    <Flex flexDirection={['column', 'row']} width={'100%'} gap={'16px'}>
                        <Flex flex={1} gap={'16px'} alignItems={'flex-start'} flexDirection={'column'}>
                            <Button
                                size={'md'}
                                onClick={handleFeedback}
                                variant={'link'}
                                fontSize={'14px'}
                                fontWeight={400}
                            >
                                Share your feedback
                            </Button>
                            <Button
                                rightIcon={<BiLinkExternal />}
                                target={'_blank'}
                                href={'https://discord.gg/YqD7v2QQ5d'}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                fontSize={'14px'}
                                fontWeight={400}
                            >
                                Join our Discord
                            </Button>
                            <Button
                                target={'_blank'}
                                href={'https://reka.ai/careers/'}
                                rightIcon={<BiLinkExternal />}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                fontSize={'14px'}
                                fontWeight={400}
                            >
                                Open positions
                            </Button>
                        </Flex>
                        <Flex flex={1} gap={'12px'} alignItems={'flex-start'} flexDirection={'column'}>
                            <Button
                                target={'_blank'}
                                href={'https://reka.ai/terms-of-use/'}
                                rightIcon={<BiLinkExternal />}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                fontSize={'14px'}
                                fontWeight={400}
                            >
                                Terms of use
                            </Button>
                            <Button
                                target={'_blank'}
                                href={'https://reka.ai/privacy-policy/'}
                                rightIcon={<BiLinkExternal />}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                fontSize={'14px'}
                                fontWeight={400}
                            >
                                Privacy policy
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Section>
            <Section>
                <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'18px'}>
                    <Heading fontSize={'12px'} fontWeight={700} as={'h3'} textTransform={'uppercase'}>
                        Developers
                    </Heading>
                    <Flex flexDirection={['column', 'row']} width={'100%'} gap={'16px'}>
                        <Flex flex={1} gap={'16px'} alignItems={'flex-start'} flexDirection={'column'}>
                            <Button
                                rightIcon={<BiLinkExternal />}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                fontSize={'14px'}
                                href={'https://platform.reka.ai'}
                                fontWeight={400}
                            >
                                Reka Platform
                            </Button>
                            <Button
                                rightIcon={<BiLinkExternal />}
                                target={'_blank'}
                                href={'https://docs.reka.ai'}
                                size={'md'}
                                as={'a'}
                                variant={'link'}
                                fontSize={'14px'}
                                fontWeight={400}
                            >
                                API documentation
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Section>
        </>
    );
}
