import { useRouter } from 'next/router';
import React from 'react';

import { ChatIdMessages } from '@/components/chat-id-messages';
import { MultiModalEmptyChat } from '@/components/empty-chat';
import { InputControls } from '@/components/input-controls';
import { PDFAgentInputControls } from '@/components/input-controls/input-controls';
import { PDFChat } from '@/components/pdf-chat';
import { PerceptionAgent } from '@/components/perception-agent';
import { SpeechToSpeech } from '@/components/speech-to-speech';
import { useChatControls } from '@/contexts/chat-controls';
import { useChatId } from '@/hooks/use-chat-id';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { Chat } from '@/utils/types';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';

import { MenuDrawer } from './menu-drawer';
import { MobileRightPanelNav } from './mobile-right-panel-nav';
import { RightPanelContainer } from './right-panel-container';

export function RightChatPanel({ chat }: { chat?: Chat | null }) {
    const router = useRouter();
    let id = useChatId();
    const { noFirebase } = useAuthStore();

    const { isOpen, onOpen, onClose } = useDisclosure();
    if (chat?.id) {
        id = chat.id;
    }
    const { chatMode } = useChatControls();

    const mode = chat ? chat?.mode ?? null : chatMode;

    return (
        <>
            <RightPanelContainer fullWidth={mode === 'pdf' || mode === 'perception'}>
                <MobileRightPanelNav onOpen={onOpen} currentTitle={chat?.title} />
                <Box as="main" flex="1" overflowY="auto" position={'relative'} height={'100%'}>
                    {id === null && (!mode || mode === 'agent') && router.isReady && <MultiModalEmptyChat />}
                    {id !== null && chat && mode === null && <ChatIdMessages item={chat} />}
                    {mode === 'perception' && <PerceptionAgent chat={chat} />}
                    {mode === 'pdf' && <PDFChat chat={chat} />}
                    {mode === 'speech2speech' && <SpeechToSpeech />}
                    {mode === 'agent' && chat && <ChatIdMessages item={chat} />}
                </Box>
                {(mode === null || mode === 'agent') && (
                    <Box as="footer" p={['8px', '16px']}>
                        <InputControls />
                        <Text
                            whiteSpace={'nowrap'}
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            textStyle={'body-12'}
                            textAlign={'center'}
                            mt={['4px', '9px']}
                            color={'header-primary'}
                        >
                            Responses can be incorrect. Please verify the accuracy of crucial factual information.
                        </Text>
                        {noFirebase && (
                            <Text
                                color="red"
                                whiteSpace={'nowrap'}
                                overflow={'hidden'}
                                textOverflow={'ellipsis'}
                                textStyle={'body-12'}
                                textAlign={'center'}
                                mt={['4px', '9px']}
                            >
                                Cannot connect to firebase. You can still chat with Yasa but features like conversation
                                share and history sync are disabled. Refresh to check if this is a temporary situation.
                            </Text>
                        )}
                    </Box>
                )}
                {mode === 'pdf' && (
                    <Box as="footer" p={['8px', '16px']}>
                        <PDFAgentInputControls />
                        <Text
                            whiteSpace={'nowrap'}
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            textStyle={'body-12'}
                            textAlign={'center'}
                            mt={['4px', '9px']}
                            color={'header-primary'}
                        >
                            Responses can be incorrect. Please verify the accuracy of crucial factual information.
                        </Text>
                        {noFirebase && (
                            <Text
                                color="red"
                                whiteSpace={'nowrap'}
                                overflow={'hidden'}
                                textOverflow={'ellipsis'}
                                textStyle={'body-12'}
                                textAlign={'center'}
                                mt={['4px', '9px']}
                            >
                                Cannot connect to firebase. You can still chat with Yasa but features like conversation
                                share and history sync are disabled. Refresh to check if this is a temporary situation.
                            </Text>
                        )}
                    </Box>
                )}
            </RightPanelContainer>

            <MenuDrawer isOpen={isOpen} onClose={onClose} />
        </>
    );
}
