import audioBufferToWav from 'audiobuffer-to-wav';
import React, { useEffect, useRef, useState } from 'react';
import { BsFillMicFill, BsFillStopFill } from 'react-icons/bs';
import { MdSend } from 'react-icons/md';

import { rekaFactory } from '@/api/reka';
import cl from '@/components/components.module.css';
import { Box, IconButton, Stack, Tooltip } from '@chakra-ui/react';

import { AudioVisualizer } from './speech-bars';
import { TimeDisplay } from './speech-time';

export function SpeechToSpeech() {
    const [isRecording, setIsRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const numBars = 10;
    const [barHeights, setBarHeights] = useState<number[]>(Array(numBars).fill(numBars)); // Bar heights for visualizer
    const [seconds, setSeconds] = useState(0);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const animationIdRef = useRef<number | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const startRecording = async () => {
        const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        });

        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;
        analyser.smoothingTimeConstant = 0.75;
        const source = audioContext.createMediaStreamSource(audioStream);
        source.connect(analyser);
        analyserRef.current = analyser;

        mediaRecorderRef.current = new MediaRecorder(audioStream);
        audioChunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = async () => {
            setIsRecording(false);
            stopBars();
            stopTimer();
            setLoading(true);
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            const audioBuffer = await blobToAudioBuffer(audioBlob);
            const wavBuffer = audioBufferToWav(audioBuffer);
            const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
            const base64AudioString = await convertBlobToBase64(wavBlob);
            const reka = await rekaFactory(null);
            const { data, err } = await reka.speech2speech({ audio: base64AudioString });
            if (err) {
                setLoading(false);
                return;
            }
            const responseBase64 = `data:audio/wav;base64,${data}`;
            const audioContext = new AudioContext();
            const audio = new Audio(responseBase64);
            const source = audioContext.createMediaElementSource(audio);
            analyserRef.current = setupAnalyser(audioContext, source);
            audioRef.current = audio;
            setIsPlaying(true);
            audio.play();
            drawBars();
            audio.onended = () => {
                setLoading(false);
                setIsPlaying(false);
                stopBars();
            };
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
        startTimer();
        drawBars();
    };

    const sendRecording = async () => {
        if (!mediaRecorderRef.current) return;
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current?.stream.getTracks().forEach((track) => track.stop());
    };

    const startTimer = () => {
        intervalRef.current = setInterval(() => {
            setSeconds((prev) => prev + 1);
        }, 1000);
    };

    const stopTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setSeconds(0);
    };

    const drawBars = () => {
        if (!analyserRef.current) return;

        const analyser = analyserRef.current;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        const noiseThreshold = 20;

        const draw = () => {
            analyser.getByteFrequencyData(dataArray);
            const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;

            if (averageVolume > noiseThreshold) {
                const heights = Array.from(dataArray)
                    .slice(0, 10)
                    .map((value) => (value / 255) * 100);
                setBarHeights(heights);
            } else {
                setBarHeights(Array(10).fill(10));
            }

            animationIdRef.current = requestAnimationFrame(draw);
        };

        draw();
    };

    const stopBars = () => {
        if (animationIdRef.current !== null) {
            cancelAnimationFrame(animationIdRef.current);
            animationIdRef.current = null;
        }
        setBarHeights(Array(numBars).fill(numBars));
    };

    const stopAll = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current?.stream.getTracks().forEach((track) => track.stop());
        }
        if (analyserRef.current) {
            analyserRef.current.disconnect();
            analyserRef.current = null;
        }
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        stopTimer();
        stopBars();
        setIsRecording(false);
        setLoading(false);
        setIsPlaying(false);
    };

    useEffect(() => {
        return () => {
            stopAll();
        };
    }, []);

    return (
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
            <IconButton
                isLoading={loading && !isPlaying}
                className={isRecording ? cl.pulse : ''}
                icon={
                    isPlaying ? (
                        <BsFillStopFill fontSize={'38px'} color="#eceef0" />
                    ) : isRecording ? (
                        <MdSend color="#eceef0" />
                    ) : (
                        <BsFillMicFill color="#eceef0" />
                    )
                }
                aria-label={isPlaying ? 'Stop' : isRecording ? 'Send' : 'Record'}
                onClick={isPlaying ? stopAll : isRecording ? sendRecording : startRecording}
                boxSize="66px"
                fontSize={'26px'}
                backgroundColor={'#172437'}
                isRound
                variant={'ghost'}
            />
            <AudioVisualizer barHeights={barHeights} />
            <TimeDisplay seconds={seconds} />
        </Box>
    );
}

const setupAnalyser = (
    audioContext: AudioContext,
    audioSource: MediaStreamAudioSourceNode | MediaElementAudioSourceNode,
) => {
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 512;
    analyser.smoothingTimeConstant = 0.75;
    audioSource.connect(analyser);
    analyser.connect(audioContext.destination);
    return analyser;
};

function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64 = reader.result as string;
            console.log(base64);
            resolve(base64.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

async function blobToAudioBuffer(blob: Blob) {
    const arrayBuffer = await blob.arrayBuffer();
    // @ts-ignore
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    return audioContext.decodeAudioData(arrayBuffer);
}
