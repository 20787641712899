import { Text } from '@chakra-ui/react';

interface TimeDisplayProps {
    seconds: number;
}

const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export function TimeDisplay({ seconds }: TimeDisplayProps) {
    return (
        <Text color={'chakra-body-text'} fontSize={'16px'}>
            {formatTime(seconds)}
        </Text>
    );
}
