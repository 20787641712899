import autosize from 'autosize';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    BsFiletypeDoc,
    BsGlobe2,
    BsHandThumbsDown,
    BsHandThumbsDownFill,
    BsHandThumbsUp,
    BsHandThumbsUpFill,
    BsInfoCircle,
    BsLink,
} from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdContentCopy, MdOutlineFeedback, MdOutlineIosShare } from 'react-icons/md';
import { RiCheckLine, RiCloseLine, RiEditBoxLine } from 'react-icons/ri';

import cl from '@/components/components.module.css';
import { ChatModes, getModelSuffix, useChatControls } from '@/contexts/chat-controls';
import { useSendFeedback } from '@/hooks/use-send-feedback';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useLoggedOutChat } from '@/store/hooks/use-logged-out-chat';
import { AgentAction, Chat, DocSource, Message } from '@/utils/types';
import { UrlObjectManager } from '@/utils/url-object-manager';
import { CheckIcon } from '@chakra-ui/icons';
import {
    AbsoluteCenter,
    Box,
    Divider,
    Flex,
    HStack,
    Heading,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Spinner,
    Text,
    Textarea,
    Tooltip,
} from '@chakra-ui/react';
import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
} from '@chakra-ui/react';

import { MessageSources } from './message-sources';
import { MessageTypingEffect } from './message-typing-effect';
import TextItem from './text-item';

type ModelMessageContainer = {
    chat: Chat;
    message: string;
    type: string;
    isLoading?: boolean;
    useSearchEngine?: boolean;
    smoothStream: boolean;
    handleReSend: (messageId?: number, message?: string) => void;
    stopGeneration?: () => void;
    showFeedback?: () => void;
    showShareModal?: () => void;
    handleCodeCopy?: (code: string) => void;
    responseText?: string;
    finish_reason?: string;
    thumbs_up?: boolean | undefined;
    messageIndex: number;
    currentMessage: Message[];
    sources?: DocSource[];
    mode?: ChatModes;
    actions?: AgentAction[];
};

function MessageActions({
    chat,
    handleRegenerate,
    showFeedback,
    showShareModal,
    thumbsUp,
    messageIndex,
    currentMessage,
}: {
    chat: Chat;
    handleRegenerate: () => void;
    showFeedback: (() => void) | undefined;
    showShareModal: (() => void) | undefined;
    thumbsUp: boolean | undefined;
    messageIndex: number;
    currentMessage: Message[];
}) {
    const { handleFeedback } = useSendFeedback();
    const { enabledFeatures } = useAppConfig();
    const { noFirebase } = useAuthStore();
    const message = currentMessage[messageIndex]?.text;
    const { isLoggedOutChat } = useLoggedOutChat();
    return (
        <Flex gap={'6px'} h={'26px'} justifyContent={'flex-end'} alignItems={'center'}>
            {!isLoggedOutChat && (
                <>
                    {enabledFeatures.modelSelector && <MoreInfoButton chat={chat} />}
                    <ActionButton label={'Regenerate response'} icon={<FiRefreshCcw />} onClick={handleRegenerate} />
                    {!noFirebase && (
                        <ActionButton label={'Share chat'} icon={<MdOutlineIosShare />} onClick={showShareModal} />
                    )}
                    {thumbsUp === true && (
                        <ActionButton isActive={true} label={'Good response'} icon={<BsHandThumbsUpFill />} />
                    )}
                    {thumbsUp === false && (
                        <ActionButton isActive={true} label={'Bad response'} icon={<BsHandThumbsDownFill />} />
                    )}
                    {thumbsUp ?? (
                        <>
                            <ActionButton
                                label={'Good response'}
                                icon={<BsHandThumbsUp />}
                                onClick={() => handleFeedback(true, messageIndex, currentMessage)}
                            />
                            <ActionButton
                                label={'Bad response'}
                                icon={<BsHandThumbsDown />}
                                onClick={() => handleFeedback(false, messageIndex, currentMessage)}
                            />
                        </>
                    )}
                    <ActionButton label={'Share feedback'} icon={<MdOutlineFeedback />} onClick={showFeedback} />
                </>
            )}
            <CopyButton message={message} />
        </Flex>
    );
}

function MoreInfoButton({ chat }: { chat: Chat }) {
    return (
        <Popover>
            <PopoverTrigger>
                <IconButton
                    size={'sm'}
                    fontSize={'20px'}
                    isRound
                    variant={'ghost'}
                    icon={<BsInfoCircle />}
                    aria-label={'More information'}
                />
            </PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>Model name: {chat.modelName}</PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
}

function ActionButton({
    label,
    icon,
    onClick = () => {},
    isActive,
    className,
}: {
    label: string;
    icon: React.ReactElement;
    onClick?: () => void;
    isActive?: boolean;
    className?: string;
}) {
    return (
        <Tooltip label={label} openDelay={600}>
            <IconButton
                className={className}
                isActive={isActive}
                size={'sm'}
                fontSize={'20px'}
                isRound
                variant={'ghost'}
                _active={
                    isActive
                        ? {
                              background: 'none',
                          }
                        : {}
                }
                icon={icon}
                onClick={onClick}
                aria-label={label}
            />
        </Tooltip>
    );
}

function CopyButton({ message }: { message: string }) {
    const [copied, setCopied] = useState(false);
    const ref = useRef<number>(null);

    const animateCopied = () => {
        setCopied(true);
        if (ref.current !== null) {
            clearTimeout(ref.current);
        }
        // @ts-ignore
        ref.current = setTimeout(() => {
            setCopied(false);
            // @ts-ignore
            ref.current = null;
        }, 1500);
    };
    return (
        <Tooltip label={'Copied'} placement={'top'} isOpen={copied}>
            <Box>
                <CopyToClipboard text={message} onCopy={animateCopied}>
                    <IconButton
                        size={'sm'}
                        fontSize={'20px'}
                        isRound
                        variant={'ghost'}
                        icon={<MdContentCopy />}
                        aria-label={'Copy response'}
                    />
                </CopyToClipboard>
            </Box>
        </Tooltip>
    );
}

export function ModelMessageContainer(props: ModelMessageContainer) {
    const { requestOption } = useChatControls();
    const {
        chat,
        message,
        type,
        isLoading,
        smoothStream,
        handleReSend,
        responseText,
        thumbs_up,
        messageIndex,
        currentMessage,
        stopGeneration,
        sources,
        useSearchEngine,
        handleCodeCopy,
        showFeedback,
        showShareModal,
        finish_reason,
        mode,
        actions,
    } = props;
    const organizedActions = organizeActions(actions);
    const [codeResults, setCodeResults] = useState<{ image: string }[]>([]);
    if (organizedActions) {
        // @ts-ignore
        return (
            <>
                <Box
                    width={'100%'}
                    border="1px solid #313845"
                    borderRadius={'6px'}
                    py={['12px', '24px']}
                    px={['14px', '26px']}
                    className={cl.messageContainer}
                >
                    <Text
                        textStyle={'body-18'}
                        color={'#8151BC'}
                        fontFamily={'input-mono-medium'}
                        textAlign={'start'}
                        display={'flex'}
                        textTransform={'uppercase'}
                        mb={'12px'}
                    >
                        {!mode && (
                            <>
                                Reka {getModelSuffix(requestOption?.modelName)}
                                {useSearchEngine && sources?.length && (
                                    <Tooltip label={'Search Tool Used'}>
                                        <Text as="span" fontSize={'xs'} position={'relative'} top={'-6px'} left={'4px'}>
                                            <BsGlobe2 />
                                        </Text>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        {mode === 'pdf' && <>PDF Chat</>}
                        {mode === 'agent' && <>Nexus</>}
                    </Text>
                    <Stepper index={organizedActions.size - 1} orientation="vertical" gap="0">
                        {Array.from(organizedActions.entries())
                            .filter(([k]) => k !== 'summarize')
                            .map(([k, v], index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            active={<Spinner size={'sm'} />}
                                            complete={<CheckIcon />}
                                            incomplete={<Spinner size={'sm'} />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink="0" width={'90%'}>
                                        <StepTitle fontWeight={'bold'}>{v.label}</StepTitle>
                                        <StepDescription>
                                            <TextItem content={v.tool || v.agent} isLoading={false} />
                                        </StepDescription>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                    </Stepper>
                    {organizedActions?.has('summarize') && (
                        <TextItem isLoading={false} content={organizedActions.get('summarize')!.agent} />
                    )}
                </Box>
            </>
        );
    }
    if (isLoading) {
        return (
            <MessageTypingEffect
                useSearchEngine={useSearchEngine}
                hasSources={!!sources?.length}
                responseText={responseText}
                type={type}
                isLoading={isLoading}
                smoothStream={smoothStream}
                stopGeneration={stopGeneration!}
                handleCodeCopy={handleCodeCopy}
                mode={mode}
            />
        );
    }

    const handleCodeExec = (results: any) => {
        setCodeResults(results);
    };

    return (
        <>
            <Box
                width={'100%'}
                border="1px solid #313845"
                borderRadius={'6px'}
                py={['12px', '24px']}
                px={['14px', '26px']}
                className={cl.messageContainer}
            >
                <Text
                    textStyle={'body-18'}
                    color={'#8151BC'}
                    fontFamily={'input-mono-medium'}
                    textAlign={'start'}
                    display={'flex'}
                    textTransform={'uppercase'}
                    mb={'12px'}
                >
                    {!mode && (
                        <>
                            Reka {getModelSuffix(requestOption?.modelName)}
                            {useSearchEngine && sources?.length && (
                                <Tooltip label={'Search Tool Used'}>
                                    <Text as="span" fontSize={'xs'} position={'relative'} top={'-6px'} left={'4px'}>
                                        <BsGlobe2 />
                                    </Text>
                                </Tooltip>
                            )}
                        </>
                    )}
                    {mode === 'pdf' && <>PDF Chat</>}
                    {mode === 'agent' && <>Nexus</>}
                </Text>
                <TextItem
                    content={message}
                    isLoading={false}
                    handleCodeCopy={handleCodeCopy}
                    handleCodeExec={handleCodeExec}
                />
                {finish_reason === 'length' && (
                    <Box position="relative" padding="3">
                        <Divider borderColor={'border-alt'} />
                        <AbsoluteCenter background={'background-main'} px="6">
                            Max response length
                        </AbsoluteCenter>
                    </Box>
                )}
                {!!codeResults.length && (
                    <>
                        <Heading size={'sm'} as={'h3'}>
                            Code output:{' '}
                        </Heading>
                        <Flex my={'12px'} justifyContent={'center'}>
                            {codeResults.map((result, i) => {
                                return (
                                    <Box key={i}>
                                        <img src={result.image} height={'400px'} width={'auto'} />
                                    </Box>
                                );
                            })}
                        </Flex>
                    </>
                )}
                {sources?.length && <MessageSources sources={sources} />}
                <MessageActions
                    chat={chat}
                    handleRegenerate={() => {
                        if (stopGeneration) stopGeneration();
                        handleReSend(messageIndex - 1);
                    }}
                    showFeedback={showFeedback}
                    showShareModal={showShareModal}
                    thumbsUp={thumbs_up}
                    messageIndex={messageIndex}
                    currentMessage={currentMessage}
                />
            </Box>
        </>
    );
}

type HumanMessageContainer = {
    message: string;
    type: string;
    isLoading?: boolean;
    useSearchEngine?: boolean;
    handleReSend: (messageId?: number, message?: string) => void;
    image?: string | null;
    mediaType?: string | null;
    messageIndex: number;
    mode?: ChatModes;
};

export function HumanMessageContainer(props: HumanMessageContainer) {
    const { message, type, handleReSend, image, mediaType, isLoading, messageIndex } = props;

    const [isEditable, setEditable] = useState(false);
    const textRef = useRef<HTMLTextAreaElement>(null);
    const [cachedMedia, setCachedMedia] = useState('');
    const { isLoggedOutChat } = useLoggedOutChat();

    function handleEdited() {
        handleReSend(messageIndex, textRef.current?.value!);
        setEditable(false);
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleEdited();
        }
    };

    useEffect(() => {
        if (isEditable) {
            autosize(textRef.current!);
            textRef.current?.select();
        }
        return () => {
            if (!textRef.current) return;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            autosize.destroy(textRef.current!);
        };
    }, [isEditable]);

    useEffect(() => {
        setCachedMedia(image ? UrlObjectManager.getUrl(image) : '');
    }, [image]);
    const mediaFallback = () => {
        setCachedMedia(image || '');
    };

    return (
        <Flex
            justifyContent={'flex-end'}
            width={'100%'}
            py={['12px', '24px']}
            px={isEditable ? [] : ['14px', '26px']}
            rowGap={'12px'}
            columnGap={'8px'}
            flexDirection={'column'}
            className={cl.messageContainer}
        >
            <Flex flexDirection={'column'} flex={1}>
                {isEditable ? (
                    <Textarea
                        padding={'8px 12px'}
                        ref={textRef}
                        resize={'none'}
                        onKeyDown={handleKeyPress}
                        defaultValue={message}
                        lineHeight={'26px'}
                        whiteSpace="pre-line"
                        autoFocus={true}
                    />
                ) : (
                    <Text maxW={'100%'} marginLeft={'auto'} lineHeight={'26px'} whiteSpace="pre-line" outline={'none'}>
                        {message}
                    </Text>
                )}
                {mediaType === 'pdf' && props.mode !== 'pdf' && (
                    <Flex
                        marginLeft={'auto'}
                        p={'8px 12px'}
                        gap={'8px'}
                        alignItems={'center'}
                        fontSize={'14px'}
                        background={'whiteAlpha.200'}
                        borderRadius={'8px'}
                        display={'inline-flex'}
                        marginTop={'12px'}
                    >
                        <BsFiletypeDoc />
                        <a target={'_blank'} href={image || ''}>
                            PDF document
                        </a>
                    </Flex>
                )}
                {image && mediaType !== 'pdf' && (
                    <Box
                        w={'auto'}
                        height={'auto'}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        className={cl.messageMedia}
                        marginTop={'24px'}
                    >
                        {mediaType === 'image' && <img onError={mediaFallback} src={cachedMedia} alt="Image" />}
                        {mediaType === 'audio' && <audio onError={mediaFallback} controls src={cachedMedia} />}
                        {mediaType === 'video' && <video onError={mediaFallback} controls src={cachedMedia} />}
                    </Box>
                )}
            </Flex>
            <HStack justifyContent={'flex-end'} alignItems={'center'}>
                {!isLoading && !isEditable && !isLoggedOutChat && (
                    <ActionButton
                        label={'Edit'}
                        icon={<RiEditBoxLine />}
                        onClick={() => {
                            setEditable(true);
                        }}
                        className={cl.editable}
                    />
                )}
                {!isLoading && isEditable && (
                    <>
                        <ActionButton
                            label={'Close'}
                            icon={<RiCloseLine />}
                            onClick={() => {
                                setEditable(false);
                            }}
                        />
                        <ActionButton label={'Edit'} icon={<RiCheckLine />} onClick={handleEdited} />
                    </>
                )}
            </HStack>
        </Flex>
    );
}

function organizeActions(actions?: AgentAction[]) {
    if (!actions) return null;
    const actionsMap = new Map<string, { agent: string; tool: string; label: string }>();
    for (let item of actions) {
        if (actionsMap.has(item.action)) {
            // @ts-ignore
            actionsMap.get(item.action)![item.type] = item.content;
        } else {
            // @ts-ignore
            actionsMap.set(item.action, { [item.type]: item.content, label: item.label });
        }
    }
    return actionsMap;
}
