import { Box } from '@chakra-ui/react';

interface AudioVisualizerProps {
    barHeights: number[];
}

export function AudioVisualizer({ barHeights }: AudioVisualizerProps) {
    return (
        <Box display={'flex'} marginTop={'30px'} marginBottom={'30px'} gap={'4px'}>
            {barHeights.map((height, index) => (
                <Box
                    key={index}
                    width={'30px'}
                    height={'90px'}
                    backgroundColor={'#e1e5eb'}
                    borderRadius={index === 0 ? '3px 0 0 3px' : index === barHeights.length - 1 ? '0 3px 3px 0' : '0'}
                    display={'flex'}
                    alignItems={'flex-end'}
                    overflow={'hidden'}
                >
                    <Box
                        width={'100%'}
                        height={`${height}px`}
                        backgroundColor={'#2A3542'}
                        transition={'height 0.1s ease'}
                    />
                </Box>
            ))}
        </Box>
    );
}
