import { EventBucket } from '@/api/reka';

export async function nexusExecute(query: string) {
    const result = await fetch('/bff/nexus/execute', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    });
    return result.json();
}

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

const actionTypes = [
    'thinking',
    'search_the_web',
    'send_email',
    'search_internal_data',
    'analyze_files',
    'execute_python_code',
] as const;

type StreamResponse = {
    action: string;
    type: string;
    label: string;
    content: string;
};

export function nexusStream(query: string) {
    const queue = new EventBucket<StreamResponse>();

    fetch('/bff/nexus/stream', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
    }).then(async (response) => {
        if (!response.body) return;
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let done = false;
        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;

            if (value) {
                const chunk = decoder.decode(value, { stream: true });
                console.log(chunk);
                try {
                    const jsonStrs = splitJsonObjects(chunk);
                    console.log(jsonStrs);
                    if (jsonStrs.length === 0) {
                        jsonStrs.push(chunk);
                    }
                    for (let str of jsonStrs) {
                        const parsedChunk = JSON.parse(str);
                        const [k, v] = Object.entries(parsedChunk)[0];
                        const [action, label, type] = actionMap[k] || ['thinking', 'Thinking', 'agent'];
                        const parser = parsers[k];
                        // @ts-ignore
                        const content = parser ? parser(v.messages[0]) : '';
                        queue.push({
                            action,
                            label,
                            type,
                            content,
                        });
                    }
                } catch (e) {
                    if (chunk.includes('summarize_agent')) {
                        queue.push({
                            action: 'summarize',
                            type: 'agent',
                            label: 'Task completed',
                            content: chunk,
                        });
                    } else {
                        queue.push({
                            action: 'thinking',
                            type: 'agent',
                            label: 'Thinking',
                            content: '',
                        });
                    }
                }
            }
        }
        queue.close();
    });

    return queue.iterator;
}

function splitJsonObjects(input: string) {
    const results = [];
    let braceCount = 0;
    let currentJson = '';
    let isEscaped = false;
    let insideString = false;

    for (let i = 0; i < input.length; i++) {
        const char = input[i];

        // Handle escape sequences
        if (char === '\\' && !isEscaped) {
            isEscaped = true;
            currentJson += char;
            continue;
        }

        // Handle string boundaries
        if ((char === '"' || char === "'") && !isEscaped) {
            insideString = !insideString;
        }

        // Reset escape flag after processing an escape character
        isEscaped = false;

        // Handle braces only if not inside a string
        if (!insideString) {
            if (char === '{') {
                if (braceCount === 0) {
                    currentJson = ''; // Start a new JSON object
                }
                braceCount++;
            }

            if (char === '}') {
                braceCount--;
            }
        }

        currentJson += char;

        // If a complete JSON object is found, add it to the results
        if (braceCount === 0 && currentJson.trim() !== '') {
            results.push(currentJson.trim());
            currentJson = '';
        }
    }

    return results;
}

const parsers: Record<string, (body: any) => string> = {
    search_the_web_agent: (body: any) => `Searching the web with query: "${body.kwargs.tool_calls[0].args.query}"`,
    search_the_web_tool: (body: any) => body.kwargs.content,
    search_internal_data_agent: (body: any) => `Task: "${body.kwargs.tool_calls[0].args.task_description}"`,
    search_internal_data_tool: (body: any) =>
        `Retrieved file: "${JSON.parse(body.kwargs.content)
            .map((f: string) => f.split('/').at(-1))
            .join(', ')}"`,
    analyze_files_agent: (body: any) => body.kwargs.tool_calls[0].args.task,
    analyze_files_tool: (body: any) => body.kwargs.content,
    summarize_agent: (body: any) => body.kwargs.content,
    execute_python_code_agent: (body: any) =>
        `Executing python code: \`\`\`${body.kwargs.tool_calls[0].args.code}\`\`\``,
    execute_python_code_tool: (body: any) => `Code output: ${JSON.parse(body.kwargs.content).stdout}`,
    send_email_agent: (body: any) => `Composing email with subject: ${body.kwargs.tool_calls[0].args.subject}`,
    send_email_tool: (body: any) => `Email job status: ${body.kwargs.status}`,
};

const actionMap: Record<string, [string, string, string]> = {
    search_the_web_agent: ['search_the_web', 'Web search', 'agent'],
    search_the_web_tool: ['search_the_web', 'Web search', 'tool'],
    search_internal_data_agent: ['search_internal_data', 'File search', 'agent'],
    search_internal_data_tool: ['search_internal_data', 'File search', 'tool'],
    analyze_files_agent: ['analyze_files', 'File analysis', 'agent'],
    analyze_files_tool: ['analyze_files', 'File analysis', 'tool'],
    execute_python_code_agent: ['execute_python_code', 'Code interpreter', 'agent'],
    execute_python_code_tool: ['execute_python_code', 'Code interpreter', 'tool'],
    send_email_agent: ['send_email', 'Emailing tool', 'agent'],
    send_email_tool: ['send_email', 'Emailing tool', 'tool'],
    summarize_agent: ['summarize', 'Task completed', 'agent'],
};
